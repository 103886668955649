@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-pager-wrap-bordered {
  border-bottom: 1px solid var(--lia-bs-border-color);
  margin-bottom: 15px;

  .lia-pager {
    padding: 5px 0;
    border: none;
  }
}

.lia-pager-wrap-boxed {
  margin-top: 15px;

  .lia-pager {
    display: flex;
    align-items: center;
    width: calc(100% + 20px);
    border-radius: var(--lia-bs-border-radius);
    padding: 7px 10px;
    margin: -7px -10px;

    &:hover {
      background-color: var(--lia-bs-gray-200);
    }
  }
}

.lia-pager {
  @include default-focus-outline();
}
